import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TopicLevel2UIModel } from '@examdojo/category/v2';
import { LocalizePipe } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';

@Component({
  selector: 'dojo-assessment-topic-wrapper',
  imports: [IconComponent, NgClass, MatTooltipModule, LocalizePipe, AsyncPipe],
  templateUrl: './assessment-topic-wrapper.component.html',
  styleUrl: './assessment-topic-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentTopicWrapperComponent {
  readonly tl2s = input<TopicLevel2UIModel[]>([]);

  readonly firstTl2s = computed(() => this.tl2s().slice(0, this.maxDisplayedItems));
  readonly hasMore = computed(() => this.tl2s().length > this.maxDisplayedItems);

  readonly maxDisplayedItems = 4;
}
