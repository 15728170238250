<div class="assessment-body-topics">
  @for (topic of firstTl2s(); track topic.id; let i = $index) {
    @if (topic.icon) {
      <!--TODO: Add some skeleton elements when the topic.topicLevel1 is not yet available-->
      <div
        class="assessment-topic-circle"
        [ngClass]="[topic.topicLevel1 ? 'bg-dynamic-' + topic.topicLevel1.color_name + '-400' : '']"
        [matTooltip]="topic.code + ' - ' + (topic.short_name | localize | async)"
      >
        <dojo-icon
          [icon]="topic.icon"
          [alt]="topic.code"
          [ngClass]="[topic.topicLevel1 ? 'text-dynamic-' + topic.topicLevel1.color_name + '-50' : '']"
          [faPrefix]="'fas'"
        />
      </div>
    }
  }
  @if (hasMore()) {
    <div class="assessment-topic-circle text-neutral-0" [ngClass]="['bg-neutral-400']" [style.left]="4 * 30 + 'px'">
      +{{ tl2s().length - 4 }}
    </div>
  }
</div>
